<template>
  <div
    class="full-height"
  >
    <div class="justify-space-between">
      <div
        class="flex-1 mr-10"
      >
        <h6 class="text-left color-identify under-line-identify">결제내역</h6>
        <table
          class="table th-left td-right"
        >
          <col width="160px"/>
          <col width="auto"/>
          <tbody>
          <tr>
            <th>거래일시</th>
            <td>{{ item.paymentDate }}</td>
          </tr>
          <tr>
            <th>승인번호</th>
            <td v-if="item.approvalNumber">{{ item.approvalNumber }}</td>
            <td v-else>-</td>
          </tr>
          <tr>
            <th>카드번호</th>
            <td>{{ item.cardNumber }}</td>
          </tr>
          <tr>
            <th>카드종류</th>
            <td>{{ item.cardType }}</td>
          </tr>
          <tr>
            <th>할부개월</th>
            <td>
              <span v-if="item.installment === 0">일시불</span>
              <span v-else>{{ item.installment }}개월</span>
            </td>
          </tr>
          <tr>
            <th>공급가</th>
            <td>{{ item.vos | makeComma }}원</td>
          </tr>
          <tr>
            <th>부가세</th>
            <td>{{ item.vat | makeComma }}원</td>
          </tr>
          <tr>
            <th>총금액</th>
            <td>{{ item.amount | makeComma }}원</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div
        class="flex-1 mr-10"
      >
        <h6 class="text-left color-identify under-line-identify">정산정보</h6>
        <table
          class="table th-left td-right"
        >
          <col width="160px"/>
          <col width="auto"/>
          <tbody>
          <tr>
            <th>가맹점</th>
            <td>{{ item.shopName }}</td>
          </tr>
          <tr>
            <th>가맹점 연락처</th>
            <td>{{ item.shopHp }}</td>
          </tr>
          <tr>
            <th>배대사 은행명</th>
            <td>{{ item.bankName }}</td>
          </tr>
          <tr>
            <th>배대사 계좌번호</th>
            <td>{{ item.account }}</td>
          </tr>
          <tr>
            <th>예금주명</th>
            <td>{{ item.lessor }}</td>
          </tr>
          <tr>
            <th>입금자명</th>
            <td>{{ item.depositor }}</td>
          </tr>
          <tr>
            <th>정산금액</th>
            <td>{{ item.feeAmount | makeComma }}원</td>
          </tr>
          <tr>
            <th>입금액(충전금액)</th>
            <td>{{ item.chargeAmount | makeComma }}원</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="mt-10 text-left" v-if="item.canCancel == 1"><label><input type="checkbox" v-model="checked"/> 결제 취소시 위
      내용을 꼭 확인하세요</label></div>
  </div>
</template>

<script>
export default {
  name: 'QuickDetail'
  , props: ['item']
  , data: function () {
    return {
      checked: false,
    }
  }
  , watch: {
    'checked': {
      immediate: true
      , handler: function (call) {
        this.$emit('checked', call)
      }
    }
  }
}
</script>














